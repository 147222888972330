import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const TestimonialItem: React.FC<{ text: string; imgSrc: string; name: string }> = ({ text, imgSrc, name }) => (
  <div className="swiper-slide">
    <div className="testimonial-item">
      <p>
        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
        {text}
        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
      </p>
      <img src={imgSrc} className="testimonial-img" alt={name} />
      <h3>{name}</h3>
    </div>
  </div>
);

const Testimonials: React.FC = () => {
  const swiperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = new Swiper(swiperRef.current, {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 0,
          }
        },
      });

      return () => {
        swiper.destroy();
      };
    }
  }, []);

  return (
    <section id="testimonials" className="testimonials section-bg-alt">
      <div className="container" data-aos="fade-up">
        <div ref={swiperRef} className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">
            <TestimonialItem
              text="Thought I would tread lightly and give @BalesSJustin a try for a bit. I should've up my units instead. 💰His slips are on fire! 🔥Thanks brother! 👊🏼"
              imgSrc="https://buylowbucket.s3.amazonaws.com/assets/img/testimonials/mathiesonsmith.jpg"
              name="@MathiesonSmith"
            />
            <TestimonialItem
              text="Most reliable DubClub I've been in. Either tail it all or pick out the plays I like most."
              imgSrc="https://buylowbucket.s3.amazonaws.com/assets/img/testimonials/webbmond.jpg"
              name="@webbmond"
            />
            <TestimonialItem
              text="He doesn't miss man, if you really trying to make some money ... then you might as well buy this dubclub"
              imgSrc="https://buylowbucket.s3.amazonaws.com/assets/img/testimonials/laadre4x.jpg"
              name="@laadre4x"
            />
            <TestimonialItem
              text="Simple: more than worth it"
              imgSrc="https://buylowbucket.s3.amazonaws.com/assets/img/testimonials/whampton_1.jpg"
              name="@whampton_1"
            />
            <TestimonialItem
              text="Even on plays that miss, the volume is always there meaning reads are always good. More than worth it"
              imgSrc="https://buylowbucket.s3.amazonaws.com/assets/img/testimonials/YunoMiles69.jpg"
              name="@YunoMiles69"
            />
            <TestimonialItem
              text="I don't buy people dubclub but i decided to buy yours and safe to say I'm up units just off the month i been subscribed"
              imgSrc="https://buylowbucket.s3.amazonaws.com/assets/img/testimonials/Rwoods3x.jpg"
              name="@Rwoods3x"
            />
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;