import React, { useMemo, useState } from 'react';

interface StacksAnalysisCardProps {
  csvData: any[];
}

type StackType = 'QB-WR' | 'QB-RB' | 'QB-TE' | 'QB-RB-WR' | 'QB-WR-TE' | 'QB-RB-TE' | 'QB-WR-WR';
type FilterType = 'ALL' | StackType;

interface Stack {
  players: string[];
  type: StackType;
  team: string;
  draftIds: Set<string>;
}

interface PlayerStack {
  player: string;
  timesDrafted: number;
  totalStacks: number;
  stacks: Stack[];
}

const StacksAnalysisCard: React.FC<StacksAnalysisCardProps> = ({ csvData }) => {
  const [filter, setFilter] = useState<FilterType>('ALL');
  const [teamFilter, setTeamFilter] = useState<string>('ALL');
  const [playerSearch, setPlayerSearch] = useState<string>('');
  const [expandedPlayers, setExpandedPlayers] = useState<Set<string>>(new Set());

  const { playerStacks, stacks, teams } = useMemo(() => {
    const stackMap: { [key: string]: Stack } = {};
    const playerStackMap: { [key: string]: PlayerStack } = {};
    const teamSet = new Set<string>();
    const playerDraftCounts: { [key: string]: Set<string> } = {};

    // Group players by draft and team
    const draftTeamPlayers: { [key: string]: { [key: string]: any[] } } = {};
    csvData.forEach((row) => {
      const draftId = row.draft;
      const team = row.team;
      const playerName = `${row.firstName} ${row.lastName}`;
      
      if (!draftTeamPlayers[draftId]) {
        draftTeamPlayers[draftId] = {};
      }
      if (!draftTeamPlayers[draftId][team]) {
        draftTeamPlayers[draftId][team] = [];
      }
      draftTeamPlayers[draftId][team].push(row);
      teamSet.add(team);
      if (!playerDraftCounts[playerName]) {
        playerDraftCounts[playerName] = new Set();
      }
      playerDraftCounts[playerName].add(draftId);

      // Count times drafted for each player
      if (!playerDraftCounts[playerName]) {
        playerDraftCounts[playerName] = new Set();
      }
      playerDraftCounts[playerName].add(draftId);
    });

    const addStack = (stack: Omit<Stack, 'draftIds'>, draftId: string) => {
      const key = `${stack.type}-${stack.players.join('-')}`;
      if (!stackMap[key]) {
        stackMap[key] = { ...stack, draftIds: new Set([draftId]) };
      } else {
        stackMap[key].draftIds.add(draftId);
      }

      stack.players.forEach(player => {
        if (!playerStackMap[player]) {
          playerStackMap[player] = { 
            player, 
            timesDrafted: playerDraftCounts[player]?.size || 0,
            totalStacks: 0, 
            stacks: [] 
          };
        }
        if (!playerStackMap[player].stacks.some(s => s === stackMap[key])) {
          playerStackMap[player].stacks.push(stackMap[key]);
        }
      });
    };

    const orderPlayers = (players: string[], positions: string[]): string[] => {
      return players.sort((a, b) => {
        const posA = positions.find(pos => a.includes(pos)) || '';
        const posB = positions.find(pos => b.includes(pos)) || '';
        return positions.indexOf(posA) - positions.indexOf(posB);
      });
    };

    Object.entries(draftTeamPlayers).forEach(([draftId, teamPlayers]) => {
      Object.entries(teamPlayers).forEach(([team, players]) => {
        const qbs = players.filter(p => p.position === 'QB').map(p => `${p.firstName} ${p.lastName}`);
        const rbs = players.filter(p => p.position === 'RB').map(p => `${p.firstName} ${p.lastName}`);
        const wrs = players.filter(p => p.position === 'WR').map(p => `${p.firstName} ${p.lastName}`);
        const tes = players.filter(p => p.position === 'TE').map(p => `${p.firstName} ${p.lastName}`);

        qbs.forEach(qb => {
          rbs.forEach(rb => {
            addStack({ players: orderPlayers([qb, rb], ['QB', 'RB']), type: 'QB-RB', team }, draftId);
          });

          wrs.forEach(wr => {
            addStack({ players: orderPlayers([qb, wr], ['QB', 'WR']), type: 'QB-WR', team }, draftId);
          });

          tes.forEach(te => {
            addStack({ players: orderPlayers([qb, te], ['QB', 'TE']), type: 'QB-TE', team }, draftId);
          });

          rbs.forEach(rb => {
            wrs.forEach(wr => {
              addStack({ players: orderPlayers([qb, rb, wr], ['QB', 'RB', 'WR']), type: 'QB-RB-WR', team }, draftId);
            });
          });

          wrs.forEach((wr1, index) => {
            wrs.slice(index + 1).forEach(wr2 => {
              addStack({ players: orderPlayers([qb, wr1, wr2], ['QB', 'WR']), type: 'QB-WR-WR', team }, draftId);
            });
          });

          wrs.forEach(wr => {
            tes.forEach(te => {
              addStack({ players: orderPlayers([qb, wr, te], ['QB', 'WR', 'TE']), type: 'QB-WR-TE', team }, draftId);
            });
          });

          rbs.forEach(rb => {
            tes.forEach(te => {
              addStack({ players: orderPlayers([qb, rb, te], ['QB', 'RB', 'TE']), type: 'QB-RB-TE', team }, draftId);
            });
          });
        });
      });
    });

    // Calculate totalStacks and consolidate stacks for each player
    Object.values(playerStackMap).forEach(playerStack => {
      const consolidatedStacks = new Map<string, Stack>();
      const draftStacks = new Map<string, Stack>();

      playerStack.stacks.forEach(stack => {
        stack.draftIds.forEach(draftId => {
          const existingStack = draftStacks.get(draftId);
          if (!existingStack || existingStack.players.length < stack.players.length) {
            draftStacks.set(draftId, stack);
          }
        });
      });

      draftStacks.forEach(stack => {
        const key = stack.players.join('-');
        if (!consolidatedStacks.has(key)) {
          consolidatedStacks.set(key, { ...stack, draftIds: new Set() });
        }
        // Use forEach instead of spread operator
        stack.draftIds.forEach(draftId => {
          consolidatedStacks.get(key)!.draftIds.add(draftId);
        });
      });

      playerStack.totalStacks = draftStacks.size;
      playerStack.stacks = Array.from(consolidatedStacks.values());
    });

    return { 
      playerStacks: Object.values(playerStackMap).sort((a, b) => b.totalStacks - a.totalStacks),
      stacks: Object.values(stackMap).sort((a, b) => b.draftIds.size - a.draftIds.size),
      teams: Array.from(teamSet).sort()
    };
  }, [csvData]);

  const filteredData: (PlayerStack | Stack)[] = useMemo(() => {
    if (filter === 'ALL') {
      return playerStacks.filter(playerStack => 
        (teamFilter === 'ALL' || playerStack.stacks.some(stack => stack.team === teamFilter)) &&
        (playerSearch === '' || playerStack.player.toLowerCase().includes(playerSearch.toLowerCase()))
      );
    } else {
      return stacks.filter(stack => 
        stack.type === filter &&
        (teamFilter === 'ALL' || stack.team === teamFilter) &&
        (playerSearch === '' || stack.players.some(player => player.toLowerCase().includes(playerSearch.toLowerCase())))
      );
    }
  }, [playerStacks, stacks, filter, teamFilter, playerSearch]);

  const toggleExpanded = (player: string) => {
    setExpandedPlayers(prev => {
      const newSet = new Set(prev);
      if (newSet.has(player)) {
        newSet.delete(player);
      } else {
        newSet.add(player);
      }
      return newSet;
    });
  };

  const isPlayerStack = (item: PlayerStack | Stack): item is PlayerStack => {
    return (item as PlayerStack).player !== undefined;
  };

  return (
    <div className="card stacks-analysis">
      <h3>Stacks Analysis</h3>
      <div className="filter-buttons">
        {(['ALL', 'QB-WR', 'QB-RB', 'QB-TE', 'QB-RB-WR', 'QB-WR-TE', 'QB-RB-TE', 'QB-WR-WR'] as FilterType[]).map(filterType => (
          <button 
            key={filterType}
            onClick={() => setFilter(filterType)} 
            className={filter === filterType ? 'active' : ''}
          >
            {filterType}
          </button>
        ))}
      </div>
      <div className="filter-dropdowns">
        <select value={teamFilter} onChange={(e) => setTeamFilter(e.target.value)}>
          <option value="ALL">All Teams</option>
          {teams.map((team) => (
            <option key={team} value={team}>{team}</option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Search for a player..."
          value={playerSearch}
          onChange={(e) => setPlayerSearch(e.target.value)}
        />
      </div>
      <div className="stacks-list">
        <h4>{filter === 'ALL' ? 'Player Stacks' : `${filter} Stacks`}</h4>
        <table>
          <thead>
            <tr>
              {filter === 'ALL' ? (
                <>
                  <th>Player</th>
                  <th>Times Drafted</th>
                  <th>Total Stacks</th>
                  <th>Details</th>
                </>
              ) : (
                <>
                  <th>Stack</th>
                  <th>Team</th>
                  <th>Count</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              isPlayerStack(item) ? (
                <React.Fragment key={item.player}>
                  <tr>
                    <td>{item.player}</td>
                    <td>{item.timesDrafted}</td>
                    <td>{item.totalStacks}</td>
                    <td>
                      <button onClick={() => toggleExpanded(item.player)}>
                        {expandedPlayers.has(item.player) ? 'Hide' : 'Show'}
                      </button>
                    </td>
                  </tr>
                  {expandedPlayers.has(item.player) && (
                    <tr>
                      <td colSpan={3}>
                        <table className="nested-table">
                          <thead>
                            <tr>
                              <th>Stack</th>
                              <th>Team</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.stacks.map((stack, stackIndex) => (
                              <tr key={stackIndex}>
                                <td>{stack.players.join(', ')}</td>
                                <td>{stack.team}</td>
                                <td>{stack.draftIds.size}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ) : (
                <tr key={index}>
                  <td>{item.players.join(', ')}</td>
                  <td>{item.team}</td>
                  <td>{item.draftIds.size}</td>
                </tr>
              )
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StacksAnalysisCard;