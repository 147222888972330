import React, { useMemo } from 'react';

interface StatsCardProps {
  csvData: any[];
}

const StatsCard: React.FC<StatsCardProps> = ({ csvData }) => {
  const stats = useMemo(() => {
    const uniqueDraftIds = new Set<string>();
    const draftFees = new Map<string, number>();

    csvData.forEach((row) => {
      const draftId = row.draft;
      if (draftId) {
        uniqueDraftIds.add(draftId);

        if (row.tournamentEntryFee && !draftFees.has(draftId)) {
          draftFees.set(draftId, parseFloat(row.tournamentEntryFee));
        }
      }
    });

    const totalEntryFees = Array.from(draftFees.values()).reduce((sum, fee) => sum + fee, 0);
    const tournamentEntries = uniqueDraftIds.size;
    const feePerEntry = tournamentEntries > 0 ? totalEntryFees / tournamentEntries : 0;

    return {
      tournamentEntries,
      totalEntryFees,
      feePerEntry,
    };
  }, [csvData]);

  return (
    <div className="stats-grid">
      <div className="card">
        <h3>Total Entries</h3>
        <p className="stat-value">{stats.tournamentEntries}</p>
      </div>
      <div className="card">
        <h3>Total Entry Fees</h3>
        <p className="stat-value">${stats.totalEntryFees}</p>
      </div>
      <div className="card">
        <h3>Fee Per Entry</h3>
        <p className="stat-value">${stats.feePerEntry.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default StatsCard;