import React, { useMemo, useState } from 'react';
import '../../assets/css/BestBallDashboard.css'; // Ensure this file exists in the specified path

interface Player {
  id: string;
  pickedAt: string;
  pickNumber: string;
  appearance: string;
  firstName: string;
  lastName: string;
  team: string;
  position: string;
  draft: string;
  draftEntry: string;
  draftEntryFee: number | null;
  draftSize: number | null;
  draftTotalPrizes: number | null;
  tournamentTitle: string;
  tournament: string;
  tournamentEntryFee: number | null;
  tournamentTotalPrizes: number | null;
  tournamentSize: number | null;
  adp: string | null; // Change to string
  positionRank: string | null;
}

interface CombinedProps {
  staticData: Player[];
  userDraftData: Player[];
}

interface PlayerCardProps {
  player: Player;
  userAvgDraftPos: string | null;
  draftStatus: string;
}

const PlayerCard: React.FC<PlayerCardProps> = ({ player, userAvgDraftPos, draftStatus }) => {
  const positionColors: { [key: string]: string } = {
    QB: 'lightblue',
    RB: 'lightgreen',
    WR: 'lightcoral',
    TE: 'lightgoldenrodyellow',
    Default: 'lightgrey',
  };

  const cardStyle = {
    backgroundColor: userAvgDraftPos !== null ? positionColors[player.position] : positionColors['Default'],
  };

  return (
    <div className="player-card" style={cardStyle}>
      <h4>{player.firstName} {player.lastName}</h4>
      <p>Team: {player.team}</p>
      <p>Position: {player.position}</p>
      <p>ADP: {player.adp}</p>
      <p>User Avg Draft Position: {userAvgDraftPos !== null ? userAvgDraftPos : 'N/A'}</p>
      <p>Status: {draftStatus}</p>
    </div>
  );
};

const CombinedComponent: React.FC<CombinedProps> = ({ staticData, userDraftData }) => {
  const [showOnlyMyPlayers, setShowOnlyMyPlayers] = useState(true);
  const [viewAsDraftBoard, setViewAsDraftBoard] = useState(false);

  const highADPPlayers = useMemo(() => {
    return staticData
      .filter(player => player.adp !== null && parseFloat(player.adp) <= 215)
      .sort((a, b) => parseFloat(a.adp!) - parseFloat(b.adp!));
  }, [staticData]);

  const userDraftedPlayers = useMemo(() => {
    const playerFrequency: { [key: string]: { count: number, totalPickNumber: number } } = {};

    userDraftData.forEach((player) => {
      const playerName = `${player.firstName} ${player.lastName}`;
      const pickNumber = parseInt(player.pickNumber);

      if (!playerFrequency[playerName]) {
        playerFrequency[playerName] = { count: 0, totalPickNumber: 0 };
      }

      playerFrequency[playerName].count += 1;
      playerFrequency[playerName].totalPickNumber += pickNumber;
    });

    return playerFrequency;
  }, [userDraftData]);

  const favoritePlayers = useMemo(() => {
    const playerFrequency: { [key: string]: { count: number, totalPickNumber: number, player: Player } } = {};

    userDraftData.forEach((player) => {
      const playerName = `${player.firstName} ${player.lastName}`;
      const pickNumber = parseInt(player.pickNumber, 10);

      if (!playerFrequency[playerName]) {
        playerFrequency[playerName] = { count: 0, totalPickNumber: 0, player };
      }

      playerFrequency[playerName].count += 1;
      playerFrequency[playerName].totalPickNumber += pickNumber;
    });

    return Object.entries(playerFrequency)
      .map(([name, data]) => ({
        name,
        position: data.player.position,
        positionRank: data.player.positionRank,
        count: data.count,
        averagePickNumber: data.totalPickNumber / data.count,
        adp: data.player.adp
      }))
      .sort((a, b) => b.count - a.count)
  }, [userDraftData]);

  const displayedPlayers = useMemo(() => {
    return highADPPlayers.map((player) => {
      const playerName = `${player.firstName} ${player.lastName}`;
      const userDrafted = playerName in userDraftedPlayers;
      const userAvgDraftPos = userDrafted
        ? (userDraftedPlayers[playerName].totalPickNumber / userDraftedPlayers[playerName].count).toFixed(1)
        : null;
      const draftStatus = userDrafted
        ? parseFloat(userAvgDraftPos!) < parseFloat(player.adp!)
          ? 'Reach'
          : 'Value'
        : 'N/A';
      return {
        ...player,
        userAvgDraftPos,
        draftStatus
      };
    }).filter(player => !showOnlyMyPlayers || player.userAvgDraftPos !== null);
  }, [highADPPlayers, userDraftedPlayers, showOnlyMyPlayers]);

  return (
    <div className="combined-component">
      <h3>Players with ADP of 215 or Lower</h3>
      <label>
        <input
          type="checkbox"
          checked={showOnlyMyPlayers}
          onChange={() => setShowOnlyMyPlayers(!showOnlyMyPlayers)}
        />
        Show only my players
      </label>
      <label>
        <input
          type="checkbox"
          checked={viewAsDraftBoard}
          onChange={() => setViewAsDraftBoard(!viewAsDraftBoard)}
        />
        View as draft board
      </label>
      {viewAsDraftBoard ? (
        <div className="player-cards">
          {displayedPlayers.map((player, index) => (
            <PlayerCard
              key={index}
              player={player}
              userAvgDraftPos={player.userAvgDraftPos}
              draftStatus={player.draftStatus}
            />
          ))}
        </div>
      ) : (
        <div className="card">
          <h3>Favorite Players</h3>
          <table className="favorite-players-table">
            <thead>
              <tr>
                <th>Player</th>
                <th>Position</th>
                <th>Position Rank</th>
                <th>Times Drafted</th>
                <th>Avg. Pick Number</th>
                <th>ADP</th>
              </tr>
            </thead>
            <tbody>
              {favoritePlayers.map((player, index) => (
                <tr key={index}>
                  <td>{player.name}</td>
                  <td>{player.position}</td>
                  <td>{player.positionRank || 'N/A'}</td>
                  <td>{player.count}</td>
                  <td>{player.averagePickNumber.toFixed(1)}</td>
                  <td>{player.adp !== null && !isNaN(parseFloat(player.adp)) ? parseFloat(player.adp).toFixed(1) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CombinedComponent;