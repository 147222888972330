import React, { useMemo, useState } from 'react';
import { Player } from './csvConversionUtility';

interface DraftedTeamTypesCardProps {
  csvData: Player[];
}

interface TeamPlayer {
  name: string;
  position: string;
  team: string;
}

interface DraftedTeam {
  draftId: string;
  players: TeamPlayer[];
  qbCount: number;
  rbCount: number;
  wrCount: number;
  teCount: number;
}

interface PositionFilter {
  position: 'QB' | 'RB' | 'WR' | 'TE';
  count: number | null;
}

const DraftedTeamTypesCard: React.FC<DraftedTeamTypesCardProps> = ({ csvData }) => {
  const [filters, setFilters] = useState<PositionFilter[]>([
    { position: 'QB', count: null },
    { position: 'RB', count: null },
    { position: 'WR', count: null },
    { position: 'TE', count: null },
  ]);

  const draftedTeams = useMemo(() => {
    const teamMap: { [key: string]: DraftedTeam } = {};

    csvData.forEach((row) => {
      const draftId = row.draft;
      const player: TeamPlayer = {
        name: `${row.firstName} ${row.lastName}`.trim(),
        position: row.position,
        team: row.team
      };

      if (!teamMap[draftId]) {
        teamMap[draftId] = {
          draftId,
          players: [],
          qbCount: 0,
          rbCount: 0,
          wrCount: 0,
          teCount: 0
        };
      }

      teamMap[draftId].players.push(player);
      switch (player.position) {
        case 'QB': teamMap[draftId].qbCount++; break;
        case 'RB': teamMap[draftId].rbCount++; break;
        case 'WR': teamMap[draftId].wrCount++; break;
        case 'TE': teamMap[draftId].teCount++; break;
      }
    });

    return Object.values(teamMap);
  }, [csvData]);

  const filteredTeams = useMemo(() => {
    return draftedTeams.filter(team => 
      filters.every(filter => {
        if (filter.count === null) return true;
        switch (filter.position) {
          case 'QB': return team.qbCount === filter.count;
          case 'RB': return team.rbCount === filter.count;
          case 'WR': return team.wrCount === filter.count;
          case 'TE': return team.teCount === filter.count;
          default: return true;
        }
      })
    );
  }, [draftedTeams, filters]);

  const handleFilterChange = (position: 'QB' | 'RB' | 'WR' | 'TE', value: string) => {
    const numValue = value === '' ? null : parseInt(value, 10);
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.position === position ? { ...filter, count: numValue } : filter
      )
    );
  };

  return (
    <div className="card drafted-team-types">
      <h3>Drafted Team Types Analysis</h3>
      <div className="filter-inputs">
        {filters.map(filter => (
          <div key={filter.position} className="filter-input">
            <label htmlFor={`${filter.position}-filter`}>{filter.position}:</label>
            <input
              id={`${filter.position}-filter`}
              type="number"
              min="0"
              value={filter.count === null ? '' : filter.count}
              onChange={(e) => handleFilterChange(filter.position, e.target.value)}
              placeholder="Any"
            />
          </div>
        ))}
      </div>
      <div className="lineup-count">
        Total Lineups Shown: {filteredTeams.length}
      </div>
      <div className="teams-list">
        {filteredTeams.map((team, index) => (
          <div key={index} className="team-item">
            <h4>Draft ID: {team.draftId}</h4>
            <p>QB: {team.qbCount}, RB: {team.rbCount}, WR: {team.wrCount}, TE: {team.teCount}</p>
            <div className="players-list">
              {team.players.map((player, playerIndex) => (
                <span key={playerIndex} className={`player ${player.position.toLowerCase()}`}>
                  {player.name} ({player.position} - {player.team})
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DraftedTeamTypesCard;