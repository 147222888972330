import React, { useMemo, useState } from 'react';

interface Player {
  firstName: string;
  lastName: string;
  position: string;
}

interface RecentDraftsCardProps {
  csvData: any[];
}

const RecentDraftsCard: React.FC<RecentDraftsCardProps> = ({ csvData }) => {
  const [showAllDrafts, setShowAllDrafts] = useState(false);

  const allDrafts = useMemo(() => {
    const draftMap: { [key: string]: Player[] } = {};

    csvData.forEach((row) => {
      const draftId = row['Draft'];
      if (draftId) {
        if (!draftMap[draftId]) {
          draftMap[draftId] = [];
        }
        draftMap[draftId].push({
          firstName: row['First Name'],
          lastName: row['Last Name'],
          position: row['Position'],
        });
      }
    });

    return Object.entries(draftMap)
      .map(([draftId, players]) => ({ 
        draftId, 
        players: players.sort((a, b) => {
          const positionOrder = { 'QB': 1, 'RB': 2, 'WR': 3, 'TE': 4 };
          return positionOrder[a.position as keyof typeof positionOrder] - positionOrder[b.position as keyof typeof positionOrder];
        })
      }))
      .sort((a, b) => b.draftId.localeCompare(a.draftId));
  }, [csvData]);

  const displayedDrafts = showAllDrafts ? allDrafts : allDrafts.slice(0, 10);

  const renderPlayersByPosition = (players: Player[]) => {
    const positions = ['QB', 'RB', 'WR', 'TE'];
    return positions.map(pos => {
      const positionPlayers = players.filter(p => p.position === pos);
      if (positionPlayers.length === 0) return null;
      return (
        <div key={pos}>
          <h4>{pos}</h4>
          <ul>
            {positionPlayers.map((player, index) => (
              <li key={index}>{player.firstName} {player.lastName}</li>
            ))}
          </ul>
        </div>
      );
    });
  };

  return (
    <div className="card recent-drafts">
      <h3>All Drafts</h3>
      <div className="drafts-list">
        {displayedDrafts.map((draft, index) => (
          <div key={index} className="draft-item">
            <h3 className="draft-id">Draft ID: {draft.draftId}</h3>
            <div className="draft-players">
              {renderPlayersByPosition(draft.players)}
            </div>
          </div>
        ))}
      </div>
      {allDrafts.length > 10 && !showAllDrafts && (
        <button onClick={() => setShowAllDrafts(true)} className="see-more-button">
          See More
        </button>
      )}
    </div>
  );
};

export default RecentDraftsCard;