import React, { useState, useRef } from 'react';

interface UploadCardProps {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUpload: () => void;
}

const UploadCard: React.FC<UploadCardProps> = ({ handleFileChange, handleUpload }) => {
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('File input change event:', event);
    const file = event.target.files?.[0];
    if (file) {
      console.log('File selected:', file.name);
      setFileName(file.name);
      handleFileChange(event);
    }
  };

  return (
    <div className="card upload-section">
      <label htmlFor="file-upload" className="file-input-label">
        Select CSV File
        <input
          id="file-upload"
          type="file"
          ref={fileInputRef}
          accept=".csv"
          onChange={handleFileSelect}
          className="file-input"
        />
      </label>
      {fileName && <span className="file-name">{fileName}</span>}
      <button 
        className="upload-button" 
        onClick={handleUpload}
        disabled={!fileName}
      >
        Upload CSV
      </button>
    </div>
  );
};

export default UploadCard;