import React, { useMemo } from 'react';

interface Player {
  id: string;
  pickedAt: string;
  pickNumber: string; // Change to string as well
  appearance: string;
  firstName: string;
  lastName: string;
  team: string;
  position: string;
  draft: string;
  draftEntry: string;
  draftEntryFee: number | null;
  draftSize: number | null;
  draftTotalPrizes: number | null;
  tournamentTitle: string;
  tournament: string;
  tournamentEntryFee: number | null;
  tournamentTotalPrizes: number | null;
  tournamentSize: number | null;
  adp: string | null; // Change to string
  positionRank: string | null;
}

interface FavoritePlayersCardProps {
  csvData: Player[];  // Enriched user draft data
}

const FavoritePlayersCard: React.FC<FavoritePlayersCardProps> = ({ csvData }) => {
  const favoritePlayers = useMemo(() => {
    const playerFrequency: { [key: string]: { count: number, totalPickNumber: number, player: Player } } = {};

    csvData.forEach((player) => {
      const playerName = `${player.firstName} ${player.lastName}`;
      const pickNumber = parseInt(player.pickNumber, 10);

      if (!playerFrequency[playerName]) {
        playerFrequency[playerName] = { count: 0, totalPickNumber: 0, player };
      }

      playerFrequency[playerName].count += 1;
      playerFrequency[playerName].totalPickNumber += pickNumber;
    });

    return Object.entries(playerFrequency)
      .map(([name, data]) => ({
        name,
        position: data.player.position,
        positionRank: data.player.positionRank,
        count: data.count,
        averagePickNumber: data.totalPickNumber / data.count,
        adp: data.player.adp
      }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);  // Top 10 most drafted players
  }, [csvData]);

  if (csvData.length === 0) {
    return <div>No player data available.</div>;
  }

  return (
    <div className="card">
      <h3>Favorite Players</h3>
      <table className="favorite-players-table">
        <thead>
          <tr>
            <th>Player</th>
            <th>Position</th>
            <th>Position Rank</th>
            <th>Times Drafted</th>
            <th>Avg. Pick Number</th>
            <th>ADP</th>
          </tr>
        </thead>
        <tbody>
          {favoritePlayers.map((player, index) => (
            <tr key={index}>
              <td>{player.name}</td>
              <td>{player.position}</td>
              <td>{player.positionRank || 'N/A'}</td>
              <td>{player.count}</td>
              <td>{player.averagePickNumber.toFixed(1)}</td>
              <td>{player.adp !== null && !isNaN(parseFloat(player.adp)) ? parseFloat(player.adp).toFixed(1) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FavoritePlayersCard;