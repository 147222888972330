import React from 'react';

interface PricingPlanProps {
  title: string;
  price: string;
  duration: string;
  features: string[];
  link: string;
  delay: number;
  isFeatured?: boolean;
}

const PricingPlan: React.FC<PricingPlanProps> = ({ title, price, duration, features, link, delay, isFeatured }) => (
  <div className={`col-lg-4 ${isFeatured ? 'featured-plan' : ''}`} data-aos="zoom-in" data-aos-delay={delay}>
    <div className={`box ${isFeatured ? 'featured' : ''}`}>
      <h3>{title}</h3>
      <h4><sup>$</sup>{price}<span> / {duration}</span></h4>
      <ul>
        {features.map((feature, index) => (
          <li key={index} className={feature.includes('na') ? 'na' : ''}>{feature.replace('na', '')}</li>
        ))}
      </ul>
      <div className="btn-wrap">
        <a href={link} className="btn-buy">Buy Now</a>
      </div>
    </div>
  </div>
);

const Pricing: React.FC = () => {
  return (
    <section id="pricing" className="pricing section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Pricing</h2>
        </div>
        <div className="row pricing-row">
          <PricingPlan
            title="Starter"
            price="25"
            duration="month"
            features={[
              "Expert Picks for:",
              "NFL, NBA, MLB, CFB & CBB",
              "Discord Access",
              "naMarch Madness Bracket",
              "naEarly Access to Tools",
              "na1-on-1 With Justin"
            ]}
            link="https://dubclub.win/r/p/pri-ranfp/?checkout=1"
            delay={100}
          />
          <PricingPlan
            title="Hall of Fame"
            price="230"
            duration="year"
            features={[
              "Expert Picks for:",
              "NFL, NBA, MLB, CFB & CBB",
              "Discord Access",
              "March Madness Bracket",
              "Early Access to Tools",
              "1-on-1 With Justin"
            ]}
            link="https://dubclub.win/r/p/pri-pjsbw/?checkout=1"
            delay={200}
            isFeatured={true}
          />
          <PricingPlan
            title="All Star"
            price="130"
            duration="6 months"
            features={[
              "Expert Picks for:",
              "NFL, NBA, MLB, CFB & CBB",
              "Discord Access",
              "March Madness Bracket",
              "naEarly Access to Tools",
              "na1-on-1 With Justin"
            ]}
            link="https://dubclub.win/r/p/pri-6n684/?checkout=1"
            delay={300}
          />
        </div>
      </div>
    </section>
  );
};

export default Pricing;