import React, { useState, useEffect } from 'react';

const ThemeSwitcher: React.FC = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? 'dark' : 'light';
  });

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <label className="theme-switch">
      <input 
        type="checkbox" 
        id="theme-toggle" 
        onChange={toggleTheme} 
        checked={theme === 'dark'} 
      />
      <span className="slider round"></span>
    </label>
  );
};

export default ThemeSwitcher;