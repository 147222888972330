// src/hooks/useMainJs.ts
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';
import PureCounter from '@srexi/purecounterjs';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

const useMainJs = () => {
    /* test code */
  useEffect(() => {
    // Theme switcher
    const themeToggle = document.getElementById('theme-toggle');
    const body = document.body;

    themeToggle?.addEventListener('click', () => {
      if (body.getAttribute('data-theme') === 'light') {
        body.setAttribute('data-theme', 'dark');
      } else {
        body.setAttribute('data-theme', 'light');
      }
    });

    // Navbar links active state on scroll
    const navbarlinks = document.querySelectorAll<HTMLElement>('#navbar .scrollto');
    const navbarlinksActive = () => {
      const position = window.scrollY + 200;
      navbarlinks.forEach(navbarlink => {
        const hash = (navbarlink as HTMLAnchorElement).hash;
        if (!hash) return;
        const section = document.querySelector(hash) as HTMLElement;
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    };
    window.addEventListener('load', navbarlinksActive);
    window.addEventListener('scroll', navbarlinksActive);

    // Scrolls to an element with header offset
    const scrollto = (el: string) => {
      const header = document.querySelector<HTMLElement>('#header');
      const offset = header?.offsetHeight || 0;
      const elementPos = document.querySelector<HTMLElement>(el)?.offsetTop || 0;
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      });
    };

    // Toggle .header-scrolled class to #header when page is scrolled
    const selectHeader = document.querySelector<HTMLElement>('#header');
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader?.classList.add('header-scrolled');
      } else {
        selectHeader?.classList.remove('header-scrolled');
      }
    };
    window.addEventListener('load', headerScrolled);
    window.addEventListener('scroll', headerScrolled);

    // Back to top button
    const backtotop = document.querySelector<HTMLElement>('.back-to-top');
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop?.classList.add('active');
      } else {
        backtotop?.classList.remove('active');
      }
    };
    window.addEventListener('load', toggleBacktotop);
    window.addEventListener('scroll', toggleBacktotop);

    // Mobile nav toggle
    document.querySelector<HTMLElement>('.mobile-nav-toggle')?.addEventListener('click', function (e) {
      document.querySelector('#navbar')?.classList.toggle('navbar-mobile');
      if (this instanceof HTMLElement) {
        this.classList.toggle('bi-list');
        this.classList.toggle('bi-x');
      }
    });

    // Mobile nav dropdowns activate
    document.querySelectorAll<HTMLElement>('.navbar .dropdown > a').forEach(el => {
      el.addEventListener('click', function (e) {
        if (document.querySelector('#navbar')?.classList.contains('navbar-mobile')) {
          e.preventDefault();
          if (this.nextElementSibling instanceof HTMLElement) {
            this.nextElementSibling.classList.toggle('dropdown-active');
          }
        }
      });
    });

    // Scroll with offset on links with a class name .scrollto
    document.querySelectorAll<HTMLElement>('.scrollto').forEach(el => {
      el.addEventListener('click', function (e) {
        const hash = (this as HTMLAnchorElement).hash;
        if (hash && document.querySelector(hash)) {
          e.preventDefault();
          const navbar = document.querySelector('#navbar');
          if (navbar?.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile');
            const navbarToggle = document.querySelector<HTMLElement>('.mobile-nav-toggle');
            navbarToggle?.classList.toggle('bi-list');
            navbarToggle?.classList.toggle('bi-x');
          }
          scrollto(hash);
        }
      });
    });

    // Scroll with offset on page load with hash links in the url
    window.addEventListener('load', () => {
      if (window.location.hash) {
        if (document.querySelector(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });

    // Preloader
    const preloader = document.querySelector<HTMLElement>('#preloader');
    if (preloader) {
      window.addEventListener('load', () => {
        preloader.remove();
      });
    }

    // Initiate glightbox
    GLightbox({ selector: '.glightbox' });

    // Testimonials slider
    new Swiper('.testimonials-slider', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    });

    // Initiate portfolio lightbox
    GLightbox({ selector: '.portfolio-lightbox' });

    // Portfolio details slider
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      loop: true,
      autoplay: { delay: 5000, disableOnInteraction: false },
      pagination: { el: '.swiper-pagination', type: 'bullets', clickable: true }
    });

    // Animation on scroll
    window.addEventListener('load', () => {
      AOS.init({ duration: 1000, easing: 'ease-in-out', once: true, mirror: false });
    });

    // Initiate Pure Counter
    new PureCounter();
  }, []);
};

export default useMainJs;
