import Papa from 'papaparse';

export interface Player {
  id: string;
  pickedAt: string;
  pickNumber: string;
  appearance: string;
  firstName: string;
  lastName: string;
  team: string;
  position: string;
  draft: string;
  draftEntry: string;
  draftEntryFee: number | null;
  draftSize: number | null;
  draftTotalPrizes: number | null;
  tournamentTitle: string;
  tournament: string;
  tournamentEntryFee: number | null;
  tournamentTotalPrizes: number | null;
  tournamentSize: number | null;
  adp: string | null;
  positionRank: string | null;
}

export interface SiteWidePlayerData {
  firstName: string;
  lastName: string;
  adp: string;
  positionRank: string;
}

export const convertCSVToJSON = (csvText: string): Player[] => {
  const { data } = Papa.parse(csvText, { header: true, skipEmptyLines: true });

  return data.map((row: any) => ({
    id: row['Draft Entry'] || `${row['First Name']}-${row['Last Name']}`,
    pickedAt: row['Picked At'] || '',
    pickNumber: row['Pick Number'] || '',
    appearance: row['Appearance'] || '',
    firstName: row['First Name'] || '',
    lastName: row['Last Name'] || '',
    team: row['Team'] || '',
    position: row['Position'] || '',
    draft: row['Draft'] || '',
    draftEntry: row['Draft Entry'] || '',
    draftEntryFee: row['Draft Entry Fee'] ? parseFloat(row['Draft Entry Fee']) : null,
    draftSize: row['Draft Size'] ? parseInt(row['Draft Size'], 10) : null,
    draftTotalPrizes: row['Draft Total Prizes'] ? parseFloat(row['Draft Total Prizes']) : null,
    tournamentTitle: row['Tournament Title'] || '',
    tournament: row['Tournament'] || '',
    tournamentEntryFee: row['Tournament Entry Fee'] ? parseFloat(row['Tournament Entry Fee']) : null,
    tournamentTotalPrizes: row['Tournament Total Prizes'] ? parseFloat(row['Tournament Total Prizes']) : null,
    tournamentSize: row['Tournament Size'] ? parseInt(row['Tournament Size'], 10) : null,
    adp: null, // Initialize as null
    positionRank: null // Initialize as null
  }));
};

export const enrichUserData = (userData: Player[], siteWideData: SiteWidePlayerData[]): Player[] => {
  console.log(siteWideData);
  const siteWideMap = new Map(siteWideData.map(player => [
    `${player.firstName?.toLowerCase() || ''} ${player.lastName?.toLowerCase() || ''}`,
    { adp: player.adp, positionRank: player.positionRank }
  ]));

  return userData.map(player => {
    const fullName = `${player.firstName?.toLowerCase() || ''} ${player.lastName?.toLowerCase() || ''}`;
    const siteWideInfo = siteWideMap.get(fullName) || { adp: null, positionRank: null };

    return {
      ...player,
      adp: siteWideInfo.adp || player.adp,
      positionRank: siteWideInfo.positionRank || player.positionRank
    };
  });
};