// src/components/Faq.tsx
import React from 'react';

interface FaqItemProps {
  question: string;
  answer: string;
  id: string;
  delay: number;
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer, id, delay }) => (
  <li data-aos="fade-up" data-aos-delay={delay}>
    <i className="bx bx-help-circle icon-help"></i>
    <a data-bs-toggle="collapse" className="collapse" data-bs-target={`#${id}`} href="#hero">
      {question}
      <i className="bx bx-chevron-down icon-show"></i>
      <i className="bx bx-chevron-up icon-close"></i>
    </a>
    <div id={id} className="collapse" data-bs-parent=".faq-list">
      <p>{answer}</p>
    </div>
  </li>
);

const Faq: React.FC = () => {
  return (
    <section id="faq" className="faq section-bg-alt">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="faq-list">
          <ul>
            <FaqItem
              question="What makes our sports betting service different from others?"
              answer="Buy Low stands out due to our extensive experience and expertise in betting on NFL, MLB, College Basketball, and College Football. We focus heavily on analytics, providing data-driven predictions and strategies that significantly increase your chances of winning."
              id="faq-list-1"
              delay={0}
            />
            <FaqItem
              question="How do you use analytics in your betting predictions?"
              answer="We leverage advanced statistical models and data analysis to assess player performance, game conditions, and other critical factors. This ensures that our predictions are based on solid evidence and not just intuition, giving you a competitive edge."
              id="faq-list-2"
              delay={100}
            />
            <FaqItem
              question="How successful are your betting predictions?"
              answer="With years of experience and a proven track record, our betting predictions have consistently resulted in winning seasons. Our subscribers benefit from our expertise and successful betting history, which builds trust and confidence in our service."
              id="faq-list-3"
              delay={200}
            />
            <FaqItem
              question="What types of sports and bets do you cover?"
              answer="We currently cover player prop bets for NFL, MLB, College Basketball, and College Football. These four sports seasons cover the entirety of the year, although we’re always learning and adding new sports through our coaches."
              id="faq-list-4"
              delay={300}
            />
            <FaqItem
              question="How much time can I save by using your service?"
              answer="By leveraging our research and analysis, you can save countless hours that you would otherwise spend on personal research. We provide all the necessary insights and recommendations, allowing you to make informed bets quickly and efficiently."
              id="faq-list-5"
              delay={400}
            />
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Faq;
