import React, { useMemo, useState } from 'react';

interface Week17GameStackCardProps {
  csvData: any[];
}

interface Player {
  name: string;
  position: string;
  team: string;
}

interface GameStack {
  draftId: string;
  players: Player[];
  teams: string[];
}

const week17Schedule: { [key: string]: string } = {
  "KC": "PIT",
  "PIT": "KC",
  "BAL": "HOU",
  "HOU": "BAL",
  "SEA": "CHI",
  "CHI": "SEA",
  "GB": "MIN",
  "MIN": "GB",
  "ATL": "WAS",
  "WAS": "ATL",
  "NYJ": "BUF",
  "BUF": "NYJ",
  "ARI": "LAR",
  "LAR": "ARI",
  "TEN": "JAX",
  "JAX": "TEN",
  "DEN": "CIN",
  "CIN": "DEN",
  "LAC": "NE",
  "NE": "LAC",
  "IND": "NYG",
  "NYG": "IND",
  "LV": "NO",
  "NO": "LV",
  "CAR": "TB",
  "TB": "CAR",
  "DAL": "PHI",
  "PHI": "DAL",
  "MIA": "CLE",
  "CLE": "MIA",
  "DET": "SF",
  "SF": "DET"
};

const Week17GameStackCard: React.FC<Week17GameStackCardProps> = ({ csvData }) => {
  const [draftIdFilter, setDraftIdFilter] = useState<string>('');
  const [gameFilter, setGameFilter] = useState<string>('');

  const draftIds = useMemo(() => {
    const ids = new Set<string>();
    csvData.forEach(row => ids.add(row.draft));
    return Array.from(ids);
  }, [csvData]);

  const uniqueGames = useMemo(() => {
    const games = new Set<string>();
    Object.entries(week17Schedule).forEach(([team, opponent]) => {
      const gameKey = [team, opponent].sort().join(' vs ');
      games.add(gameKey);
    });
    return Array.from(games);
  }, []);

  const gameStacks = useMemo(() => {
    if (!draftIdFilter && !gameFilter) return [];

    const draftPlayers: { [key: string]: Player[] } = {};

    // Group players by draft
    csvData.forEach((row) => {
      const draftId = row.draft;
      const player: Player = {
        name: `${row.firstName} ${row.lastName}`,
        position: row.position,
        team: row.team
      };

      if (!draftPlayers[draftId]) {
        draftPlayers[draftId] = [];
      }
      draftPlayers[draftId].push(player);
    });

    // Find game stacks
    const stacks: GameStack[] = [];
    const addedGames = new Set<string>();
    Object.entries(draftPlayers).forEach(([draftId, players]) => {
      const teamPlayers: { [key: string]: Player[] } = {};
      players.forEach(player => {
        if (!teamPlayers[player.team]) {
          teamPlayers[player.team] = [];
        }
        teamPlayers[player.team].push(player);
      });

      Object.entries(teamPlayers).forEach(([team, playersForTeam]) => {
        const opponent = week17Schedule[team];
        if (opponent && teamPlayers[opponent]) {
          const gameKey = [team, opponent].sort().join('-');
          if (!addedGames.has(gameKey)) {
            addedGames.add(gameKey);
            const stackPlayers = [...playersForTeam, ...teamPlayers[opponent]];
            if (stackPlayers.length >= 2) {
              stacks.push({
                draftId,
                players: stackPlayers,
                teams: [team, opponent]
              });
            }
          }
        }
      });
    });

    // Apply filters
    return stacks.filter(stack => 
      (!draftIdFilter || stack.draftId === draftIdFilter) &&
      (!gameFilter || stack.teams.includes(gameFilter.split(' vs ')[0]) || stack.teams.includes(gameFilter.split(' vs ')[1]))
    );
  }, [csvData, draftIdFilter, gameFilter]);

  return (
    <div className="card week17-game-stack">
      <h3>Week 17 Game Stacks</h3>
      <div className="filters">
        <select value={draftIdFilter} onChange={e => setDraftIdFilter(e.target.value)}>
          <option value="">Select Draft ID</option>
          {draftIds.map(draftId => (
            <option key={draftId} value={draftId}>{draftId}</option>
          ))}
        </select>
        <select value={gameFilter} onChange={e => setGameFilter(e.target.value)}>
          <option value="">All Games</option>
          {uniqueGames.map(game => (
            <option key={game} value={game}>{game}</option>
          ))}
        </select>
      </div>
      <div className="game-stacks-list">
        {gameStacks.map((stack, index) => (
          <div key={index} className="game-stack-item">
            <h4>Draft ID: {stack.draftId}</h4>
            <p>Teams: {stack.teams.join(' vs ')}</p>
            <div className="players-list">
              {stack.players.map((player, playerIndex) => (
                <span key={playerIndex} className={`player ${player.position.toLowerCase()}`}>
                  {player.name} ({player.position} - {player.team})
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Week17GameStackCard;