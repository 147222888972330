import React, { useState, useMemo } from 'react';
import './PlayerTable.css';
import { teamNameToAbbreviation } from './AirYardsDashboard';

interface Player {
  name: string;
  team: string;
  gamesPlayed: number;
  airYards: number;
  airYardsPerGame: number;
  aDot: number;
  yac: number;
  yacPerGame: number;
  airYacPerGame: number;
  opponent: string;
}

interface PlayerTableProps {
  players: Player[];
}

const PlayerTable: React.FC<PlayerTableProps> = ({ players }) => {
  const [sortField, setSortField] = useState<keyof Player>('airYacPerGame');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [displayCount, setDisplayCount] = useState(10);
  const [playerNameSearch, setPlayerNameSearch] = useState('');
  const [teamNameSearch, setTeamNameSearch] = useState('');

  const filteredPlayers = useMemo(() => {
    return players.filter(player => 
      player.name.toLowerCase().includes(playerNameSearch.toLowerCase()) &&
      (teamNameSearch === '' || player.team === teamNameSearch)
    );
  }, [players, playerNameSearch, teamNameSearch]);

  const sortedPlayers = useMemo(() => {
    return [...filteredPlayers].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredPlayers, sortField, sortDirection]);

  const handleSort = (field: keyof Player) => {
    setSortField(field);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleShowMore = () => {
    setDisplayCount(prev => Math.min(prev + 10, players.length));
  };

  const handleShowAll = () => {
    setDisplayCount(players.length);
  };

  if (players.length === 0) {
    return <p>No player data available.</p>;
  }

  const displayedPlayers = sortedPlayers.slice(0, displayCount);

  const getDisplayName = (key: string) => {
    switch (key) {
      case 'gamesPlayed':
        return 'GP';
      case 'airYards':
        return 'AIR YARDS';
      case 'airYardsPerGame':
        return 'AIR YARDS/G';
      case 'name':
        return 'PLAYER';
      case 'yacPerGame':
        return 'YAC/G';
      case 'airYacPerGame':
        return 'AIRYAC/G';
      default:
        return key.charAt(0).toUpperCase() + key.slice(1);
    }
  };

  const uniqueTeams = useMemo(() => {
    const teams = Array.from(new Set(players.map(player => player.team)))
      .filter(team => team !== '2TM')
      .map(abbr => {
        const fullName = Object.keys(teamNameToAbbreviation).find(
          key => teamNameToAbbreviation[key] === abbr
        ) || abbr;
        return { abbr, fullName };
      })
      .sort((a, b) => a.fullName.localeCompare(b.fullName));
    if (players.some(player => player.team === '2TM')) {
      teams.push({ abbr: '2TM', fullName: 'Multiple Teams' });
    }
    return teams;
  }, [players]);

  return (
    <div className="table-container">
      <div className="search-controls">
        <input
          type="text"
          placeholder="Search by player name"
          value={playerNameSearch}
          onChange={(e) => setPlayerNameSearch(e.target.value)}
        />
        <select
          value={teamNameSearch}
          onChange={(e) => setTeamNameSearch(e.target.value)}
        >
          <option value="">All Teams</option>
          {uniqueTeams.map(({ abbr, fullName }) => (
            <option key={abbr} value={abbr}>
              {fullName}
            </option>
          ))}
        </select>
      </div>
      <table className="player-table">
        <thead>
          <tr>
            {['name', 'team', 'airYacPerGame', 'airYardsPerGame', 'yacPerGame', 'aDot', 'airYards', 'yac', 'gamesPlayed'].map((key) => (
              <th key={key} onClick={() => handleSort(key as keyof Player)}>
                {getDisplayName(key)}
                {sortField === key && (
                  <span className="sort-indicator">
                    {sortDirection === 'asc' ? ' ▲' : ' ▼'}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedPlayers.map((player) => (
            <tr key={player.name}>
              {['name', 'team', 'airYacPerGame', 'airYardsPerGame', 'yacPerGame', 'aDot', 'airYards', 'yac', 'gamesPlayed'].map((key) => {
                const value = player[key as keyof Player];
                if (key === 'team') {
                  return (
                    <td key={key}>
                      {value} (vs {player.opponent})
                    </td>
                  );
                }
                return (
                  <td key={key}>
                    {typeof value === 'number'
                      ? key === 'airYards' || key === 'yac' || key === 'gamesPlayed'
                        ? Math.round(value)
                        : value.toFixed(1)
                      : value}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {displayCount < players.length && (
        <div className="table-controls">
          <button onClick={handleShowMore}>Show Next 10</button>
          <button onClick={handleShowAll}>Show All</button>
        </div>
      )}
    </div>
  );
};

export default PlayerTable;