import React, { useState, useMemo } from 'react';
import './TeamTable.css';

interface Team {
  name: string;
  airYardsPerGame: number;
  yacAllowedPerGame: number;
  airYacAllowedPerGame: number;
  opponent: string;
  gamesPlayed: number;
}

interface TeamTableProps {
  teams: Team[];
}

const TeamTable: React.FC<TeamTableProps> = ({ teams }) => {
  const [sortField, setSortField] = useState<keyof Team>('airYardsPerGame');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [teamSearch, setTeamSearch] = useState('');
  const [displayCount, setDisplayCount] = useState(10);

  const sortedTeams = useMemo(() => {
    return [...teams]
      .filter(team => team.name.toLowerCase().includes(teamSearch.toLowerCase()))
      .sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [teams, sortField, sortDirection, teamSearch]);

  const handleSort = (field: keyof Team) => {
    setSortField(field);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleShowMore = () => {
    setDisplayCount(prev => Math.min(prev + 10, teams.length));
  };

  const handleShowAll = () => {
    setDisplayCount(teams.length);
  };

  if (teams.length === 0) {
    return <p>No team data available.</p>;
  }

  const getDisplayName = (key: string) => {
    switch (key) {
      case 'airYardsPerGame':
        return 'AIR YARDS/G';
      case 'yacAllowedPerGame':
        return 'YAC/G';
      case 'airYacAllowedPerGame':
        return 'AIRYAC/G';
      case 'name':
        return 'TEAM DEFENSE';
      case 'opponent':
        return 'OPPONENT';
      case 'gamesPlayed':
        return 'GAMES PLAYED';
      default:
        return key.charAt(0).toUpperCase() + key.slice(1);
    }
  };

  const columnOrder: (keyof Team)[] = ['name', 'airYacAllowedPerGame', 'airYardsPerGame', 'yacAllowedPerGame', 'gamesPlayed'];

  const displayedTeams = sortedTeams.slice(0, displayCount);

  return (
    <div className="table-container">
      <div className="search-controls">
        <select
          value={teamSearch}
          onChange={(e) => setTeamSearch(e.target.value)}
          className="team-select"
        >
          <option value="">All Teams</option>
          {teams.map((team) => (
            <option key={team.name} value={team.name}>
              {team.name}
            </option>
          ))}
        </select>
      </div>
      <table className="team-table">
        <thead>
          <tr>
            {columnOrder.map((key) => (
              <th key={key} onClick={() => handleSort(key)}>
                {getDisplayName(key)}
                {sortField === key && (
                  <span className="sort-indicator">
                    {sortDirection === 'asc' ? ' ▲' : ' ▼'}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedTeams.map((team) => (
            <tr key={team.name}>
              {columnOrder.map((key) => (
                <td key={key}>
                  {key === 'name' || key === 'opponent'
                    ? team[key]
                    : key === 'gamesPlayed'
                    ? Math.round(team[key]).toString()
                    : (team[key] as number).toFixed(1)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {displayCount < sortedTeams.length && (
        <div className="table-controls">
          <button onClick={handleShowMore}>Show Next 10</button>
          <button onClick={handleShowAll}>Show All</button>
        </div>
      )}
    </div>
  );
};

export default TeamTable;