// src/components/Hero.tsx
import React from 'react';

const Hero: React.FC = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9 text-center">
            <h1>Don't Just Win, Learn.</h1>
            <h2>Winning sports picks backed by data and experience</h2>
          </div>
        </div>
        <div className="text-center">
            <button className="btn-get-started" onClick={() => window.location.href = "https://dubclub.win/r/p/pri-ranfp/"}>Sign Up Now</button>
        </div>
        <div className="row icon-boxes">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon">
                <img src="https://buylowbucket.s3.amazonaws.com/assets/img/Underdog.png" alt="Underdog Logo" />
              </div>
              <h4 className="title"><a href="https://play.underdogfantasy.com/p-justin-bales">Underdog</a></h4>
              <p className="description">Sign up with 'BALES' to claim your Special Pick + First Time Deposit offer up to $250 in bonus cash.</p>
              <button className="btn-icon" onClick={() => window.location.href = "https://play.underdogfantasy.com/p-justin-bales"}>CLAIM $250 NOW</button>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon">
                <img src="https://buylowbucket.s3.amazonaws.com/assets/img/Sleeper.png" alt="Sleeper Logo" />
              </div>
              <h4 className="title"><a href="https://t.co/w74AJXp6ev">Sleeper</a></h4>
              <p className="description">Use PROMO CODE: 'BALES' and get up to $100 for free on your first deposit at Sleeper Fantasy.</p>
              <button className="btn-icon" onClick={() => window.location.href = "https://t.co/w74AJXp6ev"}>CLAIM $100 NOW</button>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon">
                <img src="https://buylowbucket.s3.amazonaws.com/assets/img/Chalkboard.png" alt="Chalkboard Logo" />
              </div>
              <h4 className="title"><a href="https://streaks.chalkboard.io/cUPZ/trfbvtty">Chalkboard</a></h4>
              <p className="description">Use PROMO CODE: 'BALES' and get up to $100 for free on your first deposit at Chalkboard Fantasy.</p>
              <button className="btn-icon" onClick={() => window.location.href = "https://streaks.chalkboard.io/cUPZ/trfbvtty"}>CLAIM $100 NOW</button>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="500">
            <div className="icon-box">
              <div className="icon icon-tools">
                <img src="https://buylowbucket.s3.amazonaws.com/assets/img/Tools.png" alt="Tools Icon" />
              </div>
              <h4 className="title"><a href="#tools">Our Tools</a></h4>
              <p className="description">We're cooking up some brand new tools just in time for the NFL season. Best Ball, anyone?</p>
              <button className="btn-icon" onClick={() => window.location.href = "#tools"}>LEARN MORE</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
