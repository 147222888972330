import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface PositionalPriorityCardProps {
  csvData: any[];
}

const PositionalPriorityCard: React.FC<PositionalPriorityCardProps> = ({ csvData }) => {
  const positionPriority = useMemo(() => {
    const positionOrderSums: { [key: string]: { total: number, count: number } } = {};

    csvData.forEach((row) => {
      const position = row['Position'];
      const pickNumber = parseInt(row['Pick Number'], 10);

      if (!positionOrderSums[position]) {
        positionOrderSums[position] = { total: 0, count: 0 };
      }
      positionOrderSums[position].total += pickNumber;
      positionOrderSums[position].count += 1;
    });

    return Object.entries(positionOrderSums)
      .map(([position, data]) => ({
        position,
        averagePickOrder: data.total / data.count
      }))
      .sort((a, b) => a.averagePickOrder - b.averagePickOrder);
  }, [csvData]);

  return (
    <div className="card">
      <h3>Positional Draft Priority</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={positionPriority}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="position" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="averagePickOrder" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      <div className="priority-explanation">
        <p>Lower values indicate positions drafted earlier on average.</p>
        <p>Your typical draft order:</p>
        <ol>
          {positionPriority.map((pp, index) => (
            <li key={index}>{pp.position}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default PositionalPriorityCard;