// src/components/CallToAction.tsx
import React from 'react';

const CallToAction: React.FC = () => {
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="text-center">
          <h3>Get Started With Buy Low</h3>
          <p> Unlock expert insights and data-driven predictions for NFL, MLB, College Basketball, and College Football. Sign up now to elevate your betting game and join a community of winning bettors!</p>
          <button className="cta-btn" onClick={() => window.location.href = "https://dubclub.win/r/p/pri-ranfp/"}>Sign Up</button>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;