import React, { useState, useMemo } from 'react';
import './StyleMatchup.css';

interface Team {
  name: string;
  airYardsPerGame: number;
  yacAllowedPerGame: number;
  airYacAllowedPerGame: number;
  opponent: string;
  gamesPlayed: number;
}

interface Player {
  name: string;
  team: string;
  gamesPlayed: number;
  airYards: number;
  airYardsPerGame: number;
  aDot: number;
  yac: number;
  yacPerGame: number;
  airYacPerGame: number;
  opponent: string;
}

interface StyleMatchupProps {
  players: Player[];
  teams: Team[];
}

const teamNameToAbbreviation: { [key: string]: string } = {
  "Atlanta Falcons": "ATL",
  "Buffalo Bills": "BUF",
  "Carolina Panthers": "CAR",
  "Chicago Bears": "CHI",
  "Cincinnati Bengals": "CIN",
  "Cleveland Browns": "CLE",
  "Indianapolis Colts": "IND",
  "Arizona Cardinals": "ARI",
  "Dallas Cowboys": "DAL",
  "Denver Broncos": "DEN",
  "Detroit Lions": "DET",
  "Green Bay Packers": "GNB",
  "Houston Texans": "HOU",
  "Jacksonville Jaguars": "JAX",
  "Kansas City Chiefs": "KAN",
  "Miami Dolphins": "MIA",
  "Minnesota Vikings": "MIN",
  "New Orleans Saints": "NOR",
  "New England Patriots": "NWE",
  "New York Giants": "NYG",
  "New York Jets": "NYJ",
  "Tennessee Titans": "TEN",
  "Philadelphia Eagles": "PHI",
  "Pittsburgh Steelers": "PIT",
  "Las Vegas Raiders": "LVR",
  "Los Angeles Rams": "LAR",
  "Baltimore Ravens": "BAL",
  "Los Angeles Chargers": "LAC",
  "Seattle Seahawks": "SEA",
  "San Francisco 49ers": "SFO",
  "Tampa Bay Buccaneers": "TAM",
  "Washington Commanders": "WAS"
};

const StyleMatchup: React.FC<StyleMatchupProps> = ({ players, teams }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState<'airYards' | 'yac' | 'airYac'>('airYac');
  const [gradeFilter, setGradeFilter] = useState<string>('All');
  const [displayCount, setDisplayCount] = useState<number>(10);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [selectedTopMatchup, setSelectedTopMatchup] = useState<string | null>(null);

  const calculateGrade = (percentile: number): string => {
    if (percentile >= 95) return 'A+';
    if (percentile >= 90) return 'A';
    if (percentile >= 85) return 'A-';
    if (percentile >= 80) return 'B+';
    if (percentile >= 75) return 'B';
    if (percentile >= 70) return 'B-';
    if (percentile >= 65) return 'C+';
    if (percentile >= 60) return 'C';
    if (percentile >= 55) return 'C-';
    if (percentile >= 50) return 'D+';
    if (percentile >= 45) return 'D';
    if (percentile >= 40) return 'D-';
    return 'F';
  };

  const calculateScore = (playerStat: number, opponentStat: number, category: 'airYards' | 'yac' | 'airYac'): number => {
    const matchupWeight = 0.55;
    const playerWeight = 1 - matchupWeight;
    
    // Calculate average opponent stat for each category
    const avgOpponentStat = teams.reduce((sum, team) => {
      switch (category) {
        case 'airYards':
          return sum + team.airYardsPerGame;
        case 'yac':
          return sum + team.yacAllowedPerGame;
        case 'airYac':
          return sum + team.airYacAllowedPerGame;
      }
    }, 0) / teams.length;
    
    // Normalize player stat (assuming max possible is 150)
    const normalizedPlayerStat = Math.min((playerStat / 150) * 100, 100);
    
    // Normalize opponent stat
    const scaledOpponentStat = (opponentStat / avgOpponentStat) * 100;
    const normalizedOpponentStat = Math.max(Math.min(scaledOpponentStat, 150), 50);
    
    // Calculate weighted score
    const weightedScore = (normalizedPlayerStat * playerWeight) + (normalizedOpponentStat * matchupWeight);
    
    // Apply a curve to spread out the scores and emphasize matchups
    return Math.pow(weightedScore / 100, 0.7) * 100;
  };

  const matchupScores = useMemo(() => {
    return players.map(player => {
      const opponentAbbr = player.opponent;
      const opponentFullName = Object.keys(teamNameToAbbreviation).find(
        key => teamNameToAbbreviation[key] === opponentAbbr
      );
      const opponentTeam = teams.find(team => team.name === opponentFullName);
      if (!opponentTeam) return null;

      const airYardsScore = calculateScore(player.airYardsPerGame, opponentTeam.airYardsPerGame, 'airYards');
      const yacScore = calculateScore(player.yacPerGame, opponentTeam.yacAllowedPerGame, 'yac');
      const airYacScore = calculateScore(player.airYacPerGame, opponentTeam.airYacAllowedPerGame, 'airYac');
      
      return {
        player: player.name,
        opponent: opponentTeam.name,
        airYardsScore,
        yacScore,
        airYacScore
      };
    }).filter((score): score is NonNullable<typeof score> => score !== null);
  }, [players, teams]);

  const getTopMatchups = (category: 'airYards' | 'yac' | 'airYac') => {
    const scores = matchupScores.map(score => ({
      ...score,
      categoryScore: category === 'airYards' ? score.airYardsScore :
                     category === 'yac' ? score.yacScore :
                     score.airYacScore
    }));

    const sortedScores = scores.sort((a, b) => b.categoryScore - a.categoryScore);
    const maxScore = sortedScores[0].categoryScore;
    const minScore = sortedScores[sortedScores.length - 1].categoryScore;

    return sortedScores.map(score => ({
      ...score,
      grade: calculateGrade(((score.categoryScore - minScore) / (maxScore - minScore)) * 100)
    }))
    .filter(score => gradeFilter === 'All' || score.grade === gradeFilter)
    .sort((a, b) => b.categoryScore - a.categoryScore);
  };

  const topMatchups = useMemo(() => {
    const filteredMatchups = getTopMatchups(activeTab)
      .filter(matchup =>
        matchup.player.toLowerCase().includes(searchQuery.toLowerCase()) ||
        matchup.opponent.toLowerCase().includes(searchQuery.toLowerCase())
      );
    
    return showAll ? filteredMatchups : filteredMatchups.slice(0, displayCount);
  }, [matchupScores, activeTab, gradeFilter, displayCount, showAll, searchQuery]);

  const handleShowNext10 = () => {
    setDisplayCount(prev => prev + 10);
    setShowAll(false);
  };

  const handleShowAll = () => {
    setShowAll(true);
  };

  const renderMatchupDetails = (matchup: any) => {
    const player = players.find(p => p.name === matchup.player);
    if (!player) return null;

    const opponentFullName = matchup.opponent;
    const opponentAbbr = teamNameToAbbreviation[opponentFullName];
    const opponentTeam = teams.find(t => t.name === opponentFullName);

    if (!opponentTeam) return null;

    const averageScore = matchupScores.reduce((sum, score) => {
      switch (activeTab) {
        case 'airYards':
          return sum + score.airYardsScore;
        case 'yac':
          return sum + score.yacScore;
        case 'airYac':
          return sum + score.airYacScore;
      }
    }, 0) / matchupScores.length;

    let detailContent;
    switch (activeTab) {
      case 'airYards':
        detailContent = (
          <>
            <p>{player.name} averages {player.airYardsPerGame.toFixed(2)} air yards per game, which is his total air yards divided by the number of games played.</p>
            <p>The {opponentTeam.name} allow {opponentTeam.airYardsPerGame.toFixed(2)} air yards per game to opposing offenses.</p>
            <p>Based on this matchup, {player.name} has a score of {matchup.airYardsScore.toFixed(2)}, which is {((matchup.airYardsScore / averageScore - 1) * 100).toFixed(2)}% better than the average score this week of {averageScore.toFixed(2)}.</p>
            <p>This matchup grades out as {matchup.grade}.</p>
          </>
        );
        break;
      case 'yac':
        detailContent = (
          <>
            <p>{player.name} averages {player.yacPerGame.toFixed(2)} YAC per game, which is his total yards after catch divided by the number of games played.</p>
            <p>The {opponentTeam.name} allow {opponentTeam.yacAllowedPerGame.toFixed(2)} YAC per game to opposing offenses.</p>
            <p>Based on this matchup, {player.name} has a score of {matchup.yacScore.toFixed(2)}, which is {((matchup.yacScore / averageScore - 1) * 100).toFixed(2)}% better than the average score this week of {averageScore.toFixed(2)}.</p>
            <p>This matchup grades out as {matchup.grade}.</p>
          </>
        );
        break;
      case 'airYac':
        detailContent = (
          <>
            <p>{player.name} averages {player.airYacPerGame.toFixed(2)} AIRYAC per game, which is his total air yards and yards after catch divided by the number of games played.</p>
            <p>The {opponentTeam.name} allow {opponentTeam.airYacAllowedPerGame.toFixed(2)} AIRYAC per game to opposing offenses.</p>
            <p>Based on this matchup, {player.name} has a score of {matchup.airYacScore.toFixed(2)}, which is {((matchup.airYacScore / averageScore - 1) * 100).toFixed(2)}% better than the average score this week of {averageScore.toFixed(2)}.</p>
            <p>This matchup grades out as {matchup.grade}.</p>
          </>
        );
        break;
    }

    return (
      <div className="matchup-details">
        {detailContent}
      </div>
    );
  };

  const getGradeClass = (grade: string): string => {
    return `style-matchup-grade grade-${grade.toLowerCase().replace('+', 'p').replace('-', 'm')}`;
  };

  return (
    <div className="style-matchup-container">
      <div className="style-matchup-controls">
        <input
          type="text"
          placeholder="Search for a player or team..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="style-matchup-player-search"
        />
        <select 
          className="style-matchup-grade-filter"
          value={gradeFilter} 
          onChange={(e) => setGradeFilter(e.target.value)}
        >
          <option value="All">All Grades</option>
          <option value="A+">A+</option>
          <option value="A">A</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B">B</option>
          <option value="B-">B-</option>
          <option value="C+">C+</option>
          <option value="C">C</option>
          <option value="C-">C-</option>
          <option value="D+">D+</option>
          <option value="D">D</option>
          <option value="D-">D-</option>
          <option value="F">F</option>
        </select>
      </div>

      <div className="style-matchup-top-matchups">
        <div className="style-matchup-tabs">
          <button 
              className={`style-matchup-tab ${activeTab === 'airYac' ? 'active' : ''}`}
              onClick={() => setActiveTab('airYac')}
            >
              AirYAC
            </button>
          <button 
            className={`style-matchup-tab ${activeTab === 'airYards' ? 'active' : ''}`}
            onClick={() => setActiveTab('airYards')}
          >
            Air Yards
          </button>
          <button 
            className={`style-matchup-tab ${activeTab === 'yac' ? 'active' : ''}`}
            onClick={() => setActiveTab('yac')}
          >
            YAC
          </button>
        </div>
        <ul className="style-matchup-list">
          {topMatchups.map(matchup => (
            <li 
              key={matchup.player}
              className={`style-matchup-list-item ${selectedTopMatchup === matchup.player ? 'expanded' : ''}`}
              onClick={() => setSelectedTopMatchup(selectedTopMatchup === matchup.player ? null : matchup.player)}
            >
              <div className="style-matchup-list-item-content">
                <div className="style-matchup-player-info">
                  <span className="style-matchup-player-name">{matchup.player}</span>
                  <span className="style-matchup-opponent">vs {matchup.opponent}</span>
                </div>
                <span className={getGradeClass(matchup.grade)}>{matchup.grade}</span>
              </div>
              {selectedTopMatchup === matchup.player && (
                <div className="style-matchup-details">
                  {renderMatchupDetails(matchup)}
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="style-matchup-buttons">
          {!showAll && topMatchups.length >= displayCount && (
            <button className="style-matchup-button" onClick={handleShowNext10}>Show Next 10</button>
          )}
          {!showAll && (
            <button className="style-matchup-button" onClick={handleShowAll}>Show All</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StyleMatchup;