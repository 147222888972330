// src/components/About.tsx
import React from 'react';

const About: React.FC = () => {
  return (
    <section id="about" className="about section-bg-alt">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Why you should join Buy Low</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <ul className="features-list">
              <li>
                <i className="ri-check-double-line"></i>
                <span>Expert Insights:</span> With years of experience in sports betting and a deep understanding of NFL, MLB, College Basketball, and College Football, we provide expert insights that can significantly increase winning probabilities.
              </li>
              <li>
                <i className="ri-check-double-line"></i>
                <span>Analytics-Driven Predictions:</span> Our focus on analytics ensures that all player prop bets are based on thorough statistical analysis, giving subscribers a competitive edge.
              </li>
              <li>
                <i className="ri-check-double-line"></i>
                <span>Comprehensive Coverage:</span> Covering several major sports, our service offers a wide range of betting opportunities, catering to various interests and maximizing betting potential throughout the year.
              </li>
              <li>
                <i className="ri-check-double-line"></i>
                <span>Community and Support:</span> Joining Buy Low means becoming part of a community of like-minded bettors, offering opportunities for discussion, support, and shared insights.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <ul className="features-list">
              <li>
                <i className="ri-check-double-line"></i>
                <span>Proven Track Record:</span> Our extensive experience and successful betting history serve as proof of our expertise, building trust and confidence among subscribers.
              </li>
              <li>
                <i className="ri-check-double-line"></i>
                <span>Time-Saving:</span> Subscribers save countless hours by leveraging our research and analysis, allowing them to make informed bets without the need for extensive personal research.
              </li>
              <li>
                <i className="ri-check-double-line"></i>
                <span>Exclusive Access to Insights:</span> Offering tailored advice and strategies based on individual betting preferences and risk tolerance, our service can provide a personal betting experience.
              </li>
              <li>
                <i className="ri-check-double-line"></i>
                <span>Personalized Betting Strategies:</span> Subscribers gain access to exclusive content, including detailed reports, predictions, and betting tips that are not available to the general public.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;