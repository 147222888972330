import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface TournamentStrategyCardProps {
  csvData: any[];
}

interface TournamentStrategy {
  tournamentName: string;
  entryFee: number;
  prizePool: number;
  avgFirstQBPosition: number;
  avgFirstRBPosition: number;
  avgFirstWRPosition: number;
  avgFirstTEPosition: number;
  totalDrafts: number;
}

type Position = 'QB' | 'RB' | 'WR' | 'TE';

const TournamentStrategyCard: React.FC<TournamentStrategyCardProps> = ({ csvData }) => {
  const tournamentStrategies = useMemo(() => {
    const draftData: { [key: string]: { [key: string]: { [key: string]: number } } } = {};

    // First, gather all data
    csvData.forEach((row) => {
      const tournamentName = row.tournamentTitle;
      const draftId = row.draft;
      const position = row.position as Position;
      const pickNumber = parseInt(row.pickNumber, 10);

      if (!draftData[tournamentName]) {
        draftData[tournamentName] = {};
      }
      if (!draftData[tournamentName][draftId]) {
        draftData[tournamentName][draftId] = {};
      }
      if (!draftData[tournamentName][draftId][position] || draftData[tournamentName][draftId][position] > pickNumber) {
        draftData[tournamentName][draftId][position] = pickNumber;
      }
    });

    // Then, process the data
    const strategies: { [key: string]: TournamentStrategy } = {};

    Object.entries(draftData).forEach(([tournamentName, drafts]) => {
      const strategy: TournamentStrategy = {
        tournamentName,
        entryFee: parseFloat(csvData.find(row => row.tournamentTitle === tournamentName).tournamentEntryFee),
        prizePool: parseFloat(csvData.find(row => row.tournamentTitle === tournamentName).tournamentTotalPrizes),
        avgFirstQBPosition: 0,
        avgFirstRBPosition: 0,
        avgFirstWRPosition: 0,
        avgFirstTEPosition: 0,
        totalDrafts: Object.keys(drafts).length,
      };

      Object.values(drafts).forEach((draft) => {
        strategy.avgFirstQBPosition += draft['QB'] || 0;
        strategy.avgFirstRBPosition += draft['RB'] || 0;
        strategy.avgFirstWRPosition += draft['WR'] || 0;
        strategy.avgFirstTEPosition += draft['TE'] || 0;
      });

      strategy.avgFirstQBPosition /= strategy.totalDrafts;
      strategy.avgFirstRBPosition /= strategy.totalDrafts;
      strategy.avgFirstWRPosition /= strategy.totalDrafts;
      strategy.avgFirstTEPosition /= strategy.totalDrafts;

      strategies[tournamentName] = strategy;
    });

    return Object.values(strategies);
  }, [csvData]);

  const chartData = tournamentStrategies.map((strategy) => ({
    name: strategy.tournamentName,
    QB: strategy.avgFirstQBPosition,
    RB: strategy.avgFirstRBPosition,
    WR: strategy.avgFirstWRPosition,
    TE: strategy.avgFirstTEPosition,
  }));

  return (
    <div className="card tournament-strategy">
      <h3>Tournament Strategy Comparison</h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="QB" fill="#8884d8" />
          <Bar dataKey="RB" fill="#82ca9d" />
          <Bar dataKey="WR" fill="#ffc658" />
          <Bar dataKey="TE" fill="#ff8042" />
        </BarChart>
      </ResponsiveContainer>
      <div className="strategy-analysis">
        <h4>Strategy Insights:</h4>
        {tournamentStrategies.map((strategy, index) => (
          <div key={index} className="tournament-insight">
            <h5>{strategy.tournamentName}</h5>
            <p>Entry Fee: ${strategy.entryFee.toFixed(2)} | Prize Pool: ${strategy.prizePool.toLocaleString()}</p>
            <p>Total Unique Drafts: {strategy.totalDrafts}</p>
            <p>
              Average first pick position: 
              QB: {strategy.avgFirstQBPosition.toFixed(2)}, 
              RB: {strategy.avgFirstRBPosition.toFixed(2)}, 
              WR: {strategy.avgFirstWRPosition.toFixed(2)}, 
              TE: {strategy.avgFirstTEPosition.toFixed(2)}
            </p>
          </div>
        ))}
        <p>
          {tournamentStrategies.length > 1 
            ? "You seem to adjust your draft position priorities based on the tournament type. Consider if these adjustments are intentional and effective."
            : "You've only participated in one tournament type. Consider trying different tournament types to see how you might adjust your strategy."}
        </p>
      </div>
    </div>
  );
};

export default TournamentStrategyCard;