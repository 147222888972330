import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import '../../assets/css/BestBallDashboard.css';
import UploadCard from './UploadCard';
import StatsCard from './StatsCard';
import PositionalPriorityCard from './PositionalPriorityCard';
import FavoritePlayersCard from './FavoritePlayersCard';
import RecentDraftsCard from './RecentDraftsCard';
import TournamentStrategyCard from './TournamentStrategyCard';
import StacksAnalysisCard from './StacksCard';
import DraftedTeamTypesCard from './TeamTypesCard';
import Week17GameStackCard from './Week17GameStackCard';
import HighADPPlayers from './HighADPPlayers';
import { convertCSVToJSON, enrichUserData, Player, SiteWidePlayerData } from './csvConversionUtility';

interface PlayerRow {
    firstName: string;
    lastName: string;
    adp: string;
    positionRank: string;
}

const BestBallDashboard: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [enrichedCsvData, setEnrichedCsvData] = useState<Player[]>([]);
    const [siteWideData, setSiteWideData] = useState<SiteWidePlayerData[]>([]);
    const siteWidePlayersCsvUrl = process.env.PUBLIC_URL + '/static-data/UDADP.csv';
    const [staticData, setStaticData] = useState<Player[]>([]);

    useEffect(() => {
        // Fetch and parse site-wide data
        fetch(siteWidePlayersCsvUrl)
            .then(response => response.text())
            .then(csvText => {
                const parsedData = Papa.parse<PlayerRow>(csvText, { header: true, skipEmptyLines: true }).data;
                console.log('Parsed data:', parsedData);
                if (Array.isArray(parsedData)) {
                    const cleanedData = parsedData.map((row: PlayerRow) => ({
                        firstName: row['firstName'] || '',
                        lastName: row['lastName'] || '',
                        adp: row['adp'] || '',
                        positionRank: row['positionRank'] || '',
                        // Add other necessary fields if they are part of the static data
                    }));
                    setSiteWideData(cleanedData as SiteWidePlayerData[]);
                    setStaticData(parsedData as Player[]); // Assuming the static data has the same structure as Player
                    console.log('Site-wide data loaded:', cleanedData);
                } else {
                    console.error('Parsed data is not an array:', parsedData);
                }
            })
            .catch(error => console.error('Error loading site-wide data:', error));
    }, [siteWidePlayersCsvUrl]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const handleUpload = () => {
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const userData = convertCSVToJSON(Papa.unparse(results.data));
                    const enrichedData = enrichUserData(userData, siteWideData);
                    setEnrichedCsvData(enrichedData);
                    console.log('User data loaded:', enrichedData);
                },
                error: (error) => {
                    console.error('Error parsing file:', error);
                }
            });
        } else {
            console.log('No file selected');
        }
    };

    return (
        <div className="best-ball-dashboard">
            <h1>Best Ball Dashboard</h1>
            
            <UploadCard handleFileChange={handleFileChange} handleUpload={handleUpload} />

            {enrichedCsvData.length > 0 && (
                <>
                    <StatsCard csvData={enrichedCsvData} />
                    <StacksAnalysisCard csvData={enrichedCsvData} />
                    <FavoritePlayersCard csvData={enrichedCsvData} />
                    <Week17GameStackCard csvData={enrichedCsvData} />
                    <DraftedTeamTypesCard csvData={enrichedCsvData} />
                    <TournamentStrategyCard csvData={enrichedCsvData} />
                    <PositionalPriorityCard csvData={enrichedCsvData} />
                    <HighADPPlayers staticData={staticData} userDraftData={enrichedCsvData} />
                    <RecentDraftsCard csvData={enrichedCsvData} />
                </>
            )}
        </div>
    );
};

export default BestBallDashboard;